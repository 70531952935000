import React, { useContext } from "react"

import { Container, Section } from "components/anti/grid/grid"
import { Button } from "components/anti/buttons/buttons"
import { Image } from "components/anti/image/image"
import { Link } from "components/anti/link/link"
import { Breadcrumb } from "components/anti/breadcrumb/breadcrumb"

//Load Context
import { LangContext } from "../../../../context/lang-context"

export const TheTeamDetailsHeader = ({ data, slug }) => {
  const { lang } = useContext(LangContext)
  const block = data

  return (
    <Section className="the-team-details-header pb-0 pt-4">
      <Container>
        <div className="breadcrumb-wrapper">
          {<Breadcrumb path={`/the-team/${slug}`} theme="light" />}
        </div>
        <div className="the-team-details-header-box">
          <div className="row row-0">
            <div className="col-md-4">
              <Image
                src={block?.image?.sourceUrl}
                className="img-fluid"
                ratio="r-1-1 r-md-3-4 r-lg-1-1"
                alt="Team"
              />
            </div>
            <div className="col-md-8 col-content">
              <h6>{block?.jobTitle}</h6>
              <h1>{block?.name}</h1>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to={`tel:${block?.phoneNo}`} className="nav-link">
                    <i className="ais ai-phone-alt" />
                    {block?.phoneNo}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`mailto:${block?.email}`} className="nav-link">
                    <i className="ais ai-envelope" />
                    {block?.email}
                  </Link>
                </li>
              </ul>
              {block.vcardUrl && (
                <Button
                  variant="link"
                  link={`${block?.vcardUrl.mediaItemUrl}`}
                  target={`${block?.vcardUrl.mediaItemUrl}`}
                >
                  {lang === "EN" ? "Download" : "Unduh"} VCard
                </Button>
              )}
            </div>
          </div>
          <div className="graphic-2" />
        </div>
      </Container>
      <div className="the-team-details-header-bg">
        <Container>
          <div className="graphic-1" />
        </Container>
      </div>
    </Section>
  )
}
