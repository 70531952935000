import React, { useContext } from "react"

import { Container, Section } from "components/anti/grid/grid"
import { Card } from "components/anti/card/card"

//Load Context
import { LangContext } from "../../../../context/lang-context"

export const TheTeamDetailsRecognitions = ({ data }) => {
  const { lang } = useContext(LangContext)
  return (
    <>
      {data.length > 0 && (
        <Section className="the-team-details-recognitions">
          <Container>
            <h6>{lang === "EN" ? "Recognitions" : "Recognitions"}</h6>
            <div className="row">
              {data.map((award, i) => (
                <div className="col-md-4" key={i}>
                  <Card
                    isLink={false}
                    variant="boxless"
                    img={award?.award?.image?.sourceUrl}
                    imgRatio="r-3-2"
                    label={award?.award?.from}
                    title={award?.award?.name}
                    className="card-award"
                  >
                    <p className="card-text">
                      {lang === "EN" ? "Awarded to" : "Diberikan Kepada"}{" "}
                      <strong>{award?.award?.to}</strong>
                    </p>
                  </Card>
                </div>
              ))}
            </div>
          </Container>
        </Section>
      )}
    </>
  )
}
