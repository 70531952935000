import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export const Breadcrumb = ({ path, theme, className, isLink }) => {
  const pathNames = typeof path === "string" && path.split("/")

  return (
    <ol className={`breadcrumb ${theme} ${className}`}>
      {typeof path === "string" &&
        pathNames.map((item, index) => {
          if (index === 0) {
            return (
              <li className="breadcrumb-item" key={`path-home`}>
                {isLink ? <Link to="/">Home</Link> : <span>Home</span>}
              </li>
            )
          } else if (index === pathNames.length - 1 && item !== "") {
            return (
              <li className="breadcrumb-item" key={`path-${item}`}>
                <span>{item.split("-").join(" ")}</span>
              </li>
            )
          } else if (item === "") {
            return null
          } else {
            let url = pathNames.slice(0, index + 1).join("/")
            return (
              <li className="breadcrumb-item" key={`path-${item}`}>
                {isLink ? (
                  <Link to={url}>{item.split("-").join(" ")}</Link>
                ) : (
                  <span>{item.split("-").join(" ")}</span>
                )}
              </li>
            )
          }
        })}
    </ol>
  )
}

Breadcrumb.propTypes = {
  path: PropTypes.string,
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  className: PropTypes.string,
  isLink: PropTypes.bool,
}

Breadcrumb.defaultProps = {
  theme: "",
  className: "",
  isLink: true,
}
