import React, { useContext } from "react"
import SEO from "components/seo"
import Layout from "components/layout"
import { TheTeamDetailsHeader } from "components/shift/the-team/details/header"
import { TheTeamDetailsContent } from "components/shift/the-team/details/content"
import { TheTeamDetailsRecognitions } from "components/shift/the-team/details/recognitions"
import { AboutRequest } from "components/shift/about/request"

//Load Context
import { LangContext } from "../context/lang-context"

const TheTeamDetailsPage = ({ pageContext }) => {
  const { lang } = useContext(LangContext)

  const block =
    lang === "EN"
      ? pageContext?.data?.team
      : pageContext?.data?.translation?.team || pageContext?.data?.team

  const request =
    lang === "EN"
      ? pageContext?.request?.aboutPage?.request
      : pageContext?.request?.translation?.aboutPage?.request ||
        pageContext?.request?.aboutPage?.request

  const awards = pageContext?.data?.team

  const slug = pageContext.data.slug

  return (
    <Layout>
      <SEO title={pageContext.data.title} />
      <TheTeamDetailsHeader data={block.header} slug={slug} />
      <TheTeamDetailsContent data={block.content} />
      <TheTeamDetailsRecognitions data={awards.awards.featured} />
      <AboutRequest data={request} />
    </Layout>
  )
}

export default TheTeamDetailsPage
