import React, { useContext } from "react"

import { Container, Section } from "components/anti/grid/grid"

//Load Context
import { LangContext } from "../../../../context/lang-context"

import graphic from "assets/img/common/shift-graphic/Frame.png"

export const TheTeamDetailsContent = ({ data }) => {
  const { lang } = useContext(LangContext)
  return (
    <>
      <Section className="the-team-details-content">
        <Container>
          <div className="row row-5">
            <div className="col-lg-8 mw-lg-750px order-lg-last mb-md-down-5">
              <h6>{lang === "EN" ? "Biography" : "Biografi"}</h6>
              <div dangerouslySetInnerHTML={{ __html: data?.biography }} />
            </div>
            <div className="col-lg-4 order-lg-first">
              <h6>{lang === "EN" ? "Expertise" : "Keahlian"}</h6>
              <ul>
                {data?.expertise.map((li, i) => (
                  <li key={i}>{li?.item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="graphic">
            <div className="circle-graphic" />
            <img src={graphic} className="img-graphic" />
          </div>
        </Container>
      </Section>
    </>
  )
}
